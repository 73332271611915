import React, { useState, useEffect } from "react";
import { FaUser, FaEnvelope, FaMobile, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { registerAPI, sendOTP, verifyOTP } from "../../apis/AuthAPI";
import './Signup.css';

const RegistrationPopup = ({ onClose, openLogin }) => {
  const navigate = useNavigate();
  const [file, setSelectedFile] = useState(null);
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [timer, setTimer] = useState(120);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    empName: "",
    password: "",
    email: "",
    number: "",
    companyAddress: "",
    description: "",
    accountHolderName: "",
    company_website_url: "",
    cin_number: "",
    privacy_policy: "",
  });
  const [error, setError] = useState({});

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    let interval;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [otpSent, timer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    validateField(name, value);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 2 * 1024 * 1024) {
        alert("File size should not exceed 2MB.");
        setSelectedFile(null);
      } else if (!['image/jpeg', 'image/png', 'application/pdf'].includes(selectedFile.type)) {
        alert("Only JPG, PNG, and PDF files are allowed.");
        setSelectedFile(null);
      } else {
        setSelectedFile(selectedFile);
      }
    }
  };

  const handleSendOtp = async () => {
    setOtpLoading(true);

    try {
      let data = { email: formData.email };
      const res = await sendOTP(data);
      if (res.status === 200) {
        setOtpSent(true);
        alert("OTP sent");
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("Error sending OTP");
    } finally {
      setOtpLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setVerifyLoading(true);
    try {
      let data = { otp, email: formData.email };
      const res = await verifyOTP(data);
      if (res.status === 200) {
        alert("OTP verified successfully");
        setOtpSent(false);
        setOtp("");
        setIsVerified(true);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert("Error verifying OTP");
    } finally {
      setVerifyLoading(false);
    }
  };

  const validateField = (name, value) => {
    let errors = { ...error };
    const nameRegex = /^[a-zA-Z\s]+$/;

    switch (name) {
      case "empName":
        
        errors.empName = value.trim() ? (nameRegex.test(value) ? "" : "Employer name Should be in characters.") : "Name is Required";
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        errors.email = value.trim() ? (emailRegex.test(value) ? "" : "A valid email is required.") : "Email is required.";
        break;
      case "number":
        errors.number = value.trim() ? (value.length === 10 ? "" : "Number must be exactly 10 digits.") : "Number is required.";
        break;
      case "password":
        errors.password = value.trim() ? "" : "Password is required.";
        break;
      case "companyAddress":
        errors.companyAddress = value.trim() ? "" : "Address is required.";
        break;
      case "description":
        errors.description = value.trim() ? "" : "Description is required.";
        break;
      case "accountHolderName":
        errors.accountHolderName = value.trim() ? "" : "Account Holder Name is required.";
        break;
      case "company_website_url":
        errors.company_website_url = value.trim() ? "" : "Company website URL is required.";
        break;
      case "cin_number":
        const cinRegex = /^[A-Z]{1}\d{5}[A-Z]{2}\d{4}[A-Z]{3}\d{6}$/;
        errors.cin_number = value.trim() ? (cinRegex.test(value) ? "" : "CIN number is invalid.") : "CIN number is required.";
        break;
      case "privacy_policy":
        errors.privacy_policy = value.trim() ? "" : "Privacy Policy is required.";
        break;
      default:
        break;
    }

    setError(errors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if OTP is verified
    if (!isVerified) {
      alert("Please verify your email first.");
      return;
    }
  
    if (Object.values(error).some(err => err)) {
      alert("Please fix the errors in the form.");
      return;
    }
  
    try {
      const data = new FormData();
      data.append("file", file);
      data.append("data", JSON.stringify(formData));
  
      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      };
      const res = await registerAPI(data, header);
  
      if (res.status === 200) {
        const responseData = res.data.data;
        localStorage.setItem("empName", responseData.empName);
        localStorage.setItem("token", responseData.token);
        localStorage.setItem("email", responseData.email);
        localStorage.setItem("userId", responseData.userId);
        localStorage.setItem("number", responseData.number);
        localStorage.setItem("active", false);
  
        let count = {
          searches: responseData.searches,
          internshipEnquiry: responseData.internshipEnquiry,
          verifiedApplication: responseData.verifiedApplication,
          ResumeView: responseData.ResumeView
        };
        localStorage.setItem('count', JSON.stringify(count));
        alert(res.data.message);
        navigate("/employer/privacypolicy");
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.error("Error during registration:", error);
      alert("Error during registration");
    }
  };
  

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 signUp-container">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-4xl w-full overflow-auto signUp-container">
        <h1 className="text-3xl font-semibold mb-4 text-center">Employer Registration</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">Employer Name</label>
              <div className="flex items-center mt-1">
                <FaUser className="mr-2" size={'20px'} />
                <input
                  type="text"
                  name="empName"
                  value={formData.empName}
                  onChange={handleChange}
                  className="p-2 border rounded w-full"
                  required
                />
              </div>
              {error.empName && <span className="text-red-400">{error.empName}</span>}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <div className="flex items-center mt-1">
                <FaEnvelope className="mr-2" size={'20px'} />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="p-2 border rounded w-full"
                  required
                />
                <button
                  type="button"
                  onClick={handleSendOtp}
                  className={`ml-2 p-2 rounded ${isVerified ? "bg-green-600 text-white" : "bg-black text-white text-sm"}`}
                  disabled={otpLoading || isVerified}
                >
                  {otpLoading ? "Sending..." : isVerified ? "Verified" : "Send OTP"}
                </button>
              </div>
              {error.email && <span className="text-red-400">{error.email}</span>}

            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Mobile Number</label>
              <div className="flex items-center mt-1">
                <FaMobile className="mr-2" />
                <input
                  type="text"
                  name="number"
                  value={formData.number}
                  onChange={handleChange}
                  className="p-2 border rounded w-full"
                  required
                />
              </div>
              {error.number && <span className="text-red-400">{error.number}</span>}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Password</label>
              <div className="flex items-center mt-1 relative">
                <FaLock className="mr-2" />
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="p-2 border rounded w-full"
                  required
                />
                <span
                  className="absolute right-3 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              {error.password && <span className="text-red-400">{error.password}</span>}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Company Address</label>
              <textarea
                name="companyAddress"
                value={formData.companyAddress}
                onChange={handleChange}
                className="p-2 border rounded w-full"
                rows={3}
                required
              />
            {error.companyAddress && <span className="text-red-400">{error.companyAddress}</span>}

            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Description</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="p-2 border rounded w-full"
                rows={3}
                required
              />
            {error.description && <span className="text-red-400">{error.description}</span>}

            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Account Holder Name</label>
              <input
                type="text"
                name="accountHolderName"
                value={formData.accountHolderName}
                onChange={handleChange}
                className="p-2 border rounded w-full"
                required
              />
            {error.accountHolderName && <span className="text-red-400">{error.accountHolderName}</span>}

            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Company Website URL</label>
              <input
                type="text"
                name="company_website_url"
                value={formData.company_website_url}
                onChange={handleChange}
                className="p-2 border rounded w-full"
                required
              />
            {error.company_website_url && <span className="text-red-400">{error.company_website_url}</span>}

            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">CIN Number</label>
              <input
                type="text"
                name="cin_number"
                value={formData.cin_number}
                onChange={handleChange}
                className="p-2 border rounded w-full"
                required
              />
              {error.cin_number && <span className="text-red-400">{error.cin_number}</span>}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Privacy Policy</label>
              <textarea
                name="privacy_policy"
                value={formData.privacy_policy}
                onChange={handleChange}
                className="p-2 border rounded w-full"
                rows={3}
                required
              />
              {error.privacy_policy && <span className="text-red-400">{error.privacy_policy}</span>}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Upload Document (Max size: 2MB, JPG/PNG/PDF)</label>
              <input
                type="file"
                onChange={handleFileChange}
                className="p-2 border rounded w-full"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Enter OTP</label>
              <div className="flex items-center mt-1">
                <input
                  type="text"
                  name="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className="p-2 border rounded w-full"
                  required
                  disabled={!otpSent}
                />
                <button
                  type="button"
                  onClick={handleVerifyOtp}
                  className={`ml-2 p-2 rounded bg-black text-white text-sm ${verifyLoading ? "opacity-50" : ""}`}
                  disabled={verifyLoading || isVerified || !otpSent}
                >
                  {verifyLoading ? "Verifying..." : "Verify OTP"}
                </button>
              </div>
              {otpSent && timer > 0 && (
                <div className="text-sm text-gray-600 mt-1">
                  OTP will expire in {timer} seconds.
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="p-2 rounded bg-gray-300 text-black hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="p-2 rounded bg-blue-600 text-white hover:bg-blue-700"
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationPopup;
