
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Internal_Navbar from "../UpdatedNav/Internal_Navbar";
import axios from "axios";
import { config } from "../../service/service";
import "../ResponsiveCss/ResponsiveCss.css";

const MonthlyPackages = () => {
  const [monthlyPackage, setMonthlyPackage] = useState([]);
  const [endDate, setEndDate] = useState();
  const [active, setActive] = useState(false);
  const [token, setToken] = useState();
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();

  // Fetch Token from localStorage
  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    setToken(savedToken);
  }, []);

  // Fetch Monthly Packages Data
  const fetchData = async () => {
    try {
      const response = await fetch(`${config.backend_url}/api/students/students-free-package`);
      const data = await response.json();
      setMonthlyPackage(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch End Date and User Status (active/inactive)
  const fetchEndDate = async () => {
    try {
      const res = await axios.get(`${config.backend_url}/api/newstudents/getuser`, {
        headers: { 'Authorization': token }
      });
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      };
      const formattedDate = formatDate(res.data.data.freemium.endDate);
      setEndDate(formattedDate);
      setActive(res.data.data.freemium.active);
    } catch (error) {
      console.error('Error fetching End date:', error);
    }
  };

  // Fetch User Data
  const fetchUserData = async (authToken) => {
    try {
      const id = localStorage.getItem('userId');
      const response = await axios.get(`${config.backend_url}/api/newstudents/getstudentbyId/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    const savedToken = localStorage.getItem("token");
    setToken(savedToken);
    if (savedToken) {
      fetchUserData(savedToken); // Fetch user data with token
      fetchEndDate(); // Fetch subscription end date
    }
    fetchData(); // Fetch monthly packages
  }, []);
  
  // Handle Subscription
  const handleSubscribe = async () => {
    if (!window.confirm("Are you sure you want to subscribe to the Freemium plan?")) {
        return;
    }

    const userId = localStorage.getItem('userId') // Get the user ID from your state or session
    const token = localStorage.getItem('token'); // Retrieve token from localStorage

    try {
        const response = await axios.patch(
            `${config.backend_url}/api/newstudents/freemium/${userId}`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (response.status === 200) {
            alert(response.data.msg);
            setActive(true); // Update the status to active
        } else if (response.status === 202) {
            alert(response.data.msg);
        }
    } catch (error) {
        console.error("Error during subscription:", error);
        alert("An error occurred. Please try again.");
    }
};

  
  // Check if the subscription is active and the end date is in the future
  const isFreemiumActiveAndFutureDate = () => {
    if (!userData?.freemium?.endDate || !userData?.freemium?.active) {
      return false;
    }
  
    const currentDate = new Date();
    const endDateObject = new Date(userData.freemium.endDate);
    return userData.freemium.active && endDateObject > currentDate;
  };

  return (
    <>
      <Internal_Navbar />
      <section className="text-black body-font flex">
        <div className="container px-5 py-5 mx-auto">
          <div className="flex flex-col text-center w-full mt-20">
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-black">
              Monthly Packages
            </h1>
            <div className="flex mx-auto border-2 border-amber-300 rounded overflow-hidden mt-6">
              <Link to={"/student/freeplan"}>
                <button className="py-1 px-4 text-black focus:outline-none">Freemium</button>
              </Link>
              <Link to={"/student/monthlyplan"}>
                <button className="py-1 px-4 text-black" title="This functionality is under development">
                  Premium
                </button>
              </Link>
            </div>
          </div>

          {monthlyPackage && monthlyPackage.length > 0 ? (
            monthlyPackage.map((data, i) => {
              const isFreemiumActive = userData.freemium?.active;
              const freemiumEndDate = userData.freemium?.endDate;
              const date = new Date(freemiumEndDate);
              const formattedEndDate = date.toLocaleDateString('en-GB');

              return (
                <div key={i} className="width-set-for-the-premium-or-freemium-packages mx-auto">
                  <div className="Freecard p-4 xl:w-full w-full">
                    <div className="Freecard h-full p-6 rounded-lg flex flex-col relative overflow-hidden">
                      <div className="Freecard p-4 w-full">
                        <div className="Freecards h-full p-6 rounded-lg border-2 border-amber-300 flex flex-col relative overflow-hidden">
                          <span className="bg-black text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                            {data.freePackagePrice === "FREEMIUM" ? "FREEMIUM" : "POPULAR"}
                          </span>
                          <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                            {data.freePackagePrice === "FREEMIUM" ? "FREE" : "PRO"}
                          </h2>
                          <h1 className="text-3xl text-black leading-none flex items-center pb-4 mb-4 border-b border-amber-300">
                            <span className="preeHeading">{data.freePackagePrice === "FREEMIUM" ? "₹FREEMIUM" : `₹${data.freePackagePrice}`}</span>
                            {data.freePackagePrice !== "FREEMIUM" && <span className="text-lg ml-1 font-normal text-black">/mo</span>}
                          </h1>
                          <p className="flex items-center text-black mb-2">
                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                              </svg>
                            </span>
                            {data.searches} Searches
                          </p>

                          <p className="flex items-center text-black mb-2">
                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                              </svg>
                            </span>
                            {data.opportunities} Opportunities
                          </p>
                          <p className="flex items-center text-black mb-2">
                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                              </svg>
                            </span>
                            {data.verified_application} Verified Apply
                          </p>
                          <p className="flex items-center text-black mb-6">
                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                <path d="M20 6L9 17l-5-5"></path>
                              </svg>
                            </span>
                            {data.dedicated_crm}
                          </p>

                          {isFreemiumActiveAndFutureDate() ? (
                            <button
                              onClick={() => navigate("/student/monthlyplan")}
                              className="flex items-center mt-auto text-black bg-amber-300 border-0 py-2 px-4 w-full focus:outline-none hover:bg-amber-400 rounded"
                            >
                              Upgrade to Premium
                              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                              </svg>
                            </button>
                          ) : (
                            <button
                              onClick={data.freePackagePrice === "FREEMIUM" ? handleSubscribe : () => {}}
                              className="flex items-center mt-auto text-black bg-amber-300 border-0 py-2 px-4 w-full focus:outline-none hover:bg-amber-400 rounded"
                            >
                              {data.freePackagePrice === "FREEMIUM" ? "Subscribe" : "Subscribe"}
                              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                              </svg>
                            </button>
                          )}

                          {isFreemiumActive && (
                            <p className="text-xs text-black mt-3">
                              Your Subscription will end on <span className="font-bold">{formattedEndDate}</span>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No packages available</p>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default MonthlyPackages;
