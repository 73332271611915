import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import Sidebar from "../../Sidebar/Sidebar";
import Footer from "../../Footer/Footer";
import "../../ResponsiveCss/ResponsiveCss.css";
import "./PostInternship.css";
import { createIntership, update } from "../../../apis/IntershipAPI";
const PostInternship = () => {
  const [posting, setPosting] = useState(false);
  // const [alert, setAlert] = useState(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    job_Title: "",
    location: "",
    company_Name: "",
    start_Date: new Date(),
    empName: "",
    empEmail: "",
    empPhone: "",
    end_Date: new Date(),
    job_Type: "Full-time",
    skills: "",
    position: "",
    job_Description: "",
    stipend: "",
    userId: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "stipend" || name === "position") {
      const regex = /^[a-zA-Z0-9 ]*$/; 
      if (!regex.test(value)) return; 
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleStartDateChange = (date) => {
    setFormData({
      ...formData,
      start_Date: date,
    });
  };

  const handleEndDateChange = (date) => {
    setFormData({
      ...formData,
      end_Date: date,
    });
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const email = localStorage.getItem("email");
    const empName = localStorage.getItem("empName");
    const number = localStorage.getItem("number");
    if (userId && email && empName && number) {
      setFormData({
        ...formData,
        userId,
        empEmail: email,
        empPhone: number,
        empName,
      });
    } else {
      console.error("Error fetching employer details from localStorage");

    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let active = localStorage.getItem('active');
      let count = JSON.parse(localStorage.getItem('count'))
      console.log(active);
      if (!active) {
        alert('Employer need to take the subscription.')
        navigate('/employer/packages')
        return;
      }
      let internshipEnquiry = JSON.parse(localStorage.getItem('count')).internshipEnquiry;
      if (!internshipEnquiry) {
        alert('Employer need to take the subscription.')
        navigate('/employer/packages')
        return;
      }
      
      const data = {
        ...formData,
      }
      const res = await createIntership(data);
      console.log(res.data.data);
      // if(res.status === 202){
      //   alert(res.data.message)
      // }
      if (res.status === 200) {
        const updatedValue = {
          internshipEnquiry: internshipEnquiry - 1
        }
        console.log(updatedValue)
        try {
          const res = await update(updatedValue);
          console.log(res);

          // updating the local storage value
          localStorage.removeItem('count');
          count.internshipEnquiry = res.data.data.internshipEnquiry
          console.log(localStorage.getItem('count'));
          console.log()
          localStorage.setItem('count', JSON.stringify(count))
          // alert("Internship Posted Successfully.")
          navigate('/employer/jobs');
        } catch (error) {
          console.log(error);
        }

      }

    } catch (error) {
      console.log(error)
    }
  }


  return (
    <>
      <div>
        <Navbar />
      </div>

      <div className="SideNPostInternship flex">
        <div>
          <Sidebar />
        </div>

        <div className="PostInternshipBody max-w-3xl mx-auto mt-3 mb-10 p-8 bg-gray-90 rounded shadow-lg ">
          <h2 className="text-3xl md:text-2xl lg:text-2xl font-bold mb-0">
            Post Internship
          </h2>
          {/* {alert && (
            <Alert type={alert.type}>
              <p className="">
                {alert.type === "success" ? "Success" : "alert "}
              </p>
              <p>{alert.message}</p>
            </Alert>
          )} */}

          <form
            onSubmit={handleSubmit}
            autoComplete="off"
            className="flex flex-wrap -mx-4"
          >
            {/* Job Title */}
            <div className="w-full md:w-1/2 px-4 mb-4">
              <label
                htmlFor="job_Title"
                className="block text-sm font-medium text-black internship-titile-for-post-internship"
              >
                Internship Title:
              </label>
              <input
                type="text"
                id="job_Title"
                name="job_Title"
                value={formData.job_Title}
                onChange={handleChange}
                className="mt-1 p-2 w-full border-2 border-amber-300 rounded-md text-large"
                placeholder="Enter Internship title here"
                required
              />
            </div>

            {/* Location */}
            <div className="w-full md:w-1/2 px-4 mb-4">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-black"
              >
                Location:
              </label>
              <input
                type="text"
                id="location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                className="mt-1 p-2 w-full border-2 border-amber-300 rounded-md text-large"
                placeholder="Enter your Internship location here"
                required
              />
            </div>

            {/* Company Name */}
            <div className="w-full md:w-1/2 px-4 mb-4">
              <label
                htmlFor="company_Name"
                className="block text-sm font-medium text-black"
              >
                Company Name:
              </label>
              <input
                type="text"
                id="company_Name"
                name="company_Name"
                value={formData.company_Name}
                onChange={handleChange}
                className="mt-1 p-2 w-full border-2 border-amber-300 rounded-md text-large"
                placeholder="Enter your Company name here"
                required
              />
            </div>
            {/* Job Type */}
            <div className="w-full md:w-1/2 px-4 mb-4">
              <label
                htmlFor="job_Type"
                className="block text-sm font-medium text-black"
              >
                Job Type:
              </label>
              <select
                id="job_Type"
                name="job_Type"
                value={formData.job_Type}
                onChange={handleChange}
                className="mt-1 p-2 w-full border-2 border-amber-300 rounded-md text-large"
                required
              >
                <option value="Full-time">Full Time</option>
                <option value="Part-time">Part Time</option>
              </select>
            </div>
            {/* Start Date */}

          
            <div className="w-full md:w-1/2 px-4 mb-4">
              <label
                htmlFor="start_Date"
                className="block text-sm font-medium text-black"
              >
                Start Date:
              </label>
              <DatePicker
                id="start_Date"
                selected={formData.start_Date}
                onChange={handleStartDateChange}
                dateFormat="dd/MM/yyyy"
                className="mt-1 p-2 w-[21rem] border-2 border-amber-300 rounded-md text-large"
                required
              />
            </div>
            {/* End Date */}
            <div className="w-full md:w-1/2 px-4 mb-4">
              <label
                htmlFor="end_Date"
                className="block text-sm font-medium text-black"
              >
                End Date:
              </label>
              <DatePicker
                id="end_Date"
                selected={formData.end_Date}
                onChange={handleEndDateChange}
                dateFormat="dd/MM/yyyy"
                className="mt-1 p-2 w-[21rem] border-2 border-amber-300 rounded-md text-large"
                required
              />
            </div>



            {/* Skills */}
            <div className="w-full px-4 mb-4">
              <label
                htmlFor="skills"
                className="block text-sm font-medium text-black"
              >
                Skills:
              </label>
              <input
                type="text"
                id="skills"
                name="skills"
                value={formData.skills}
                onChange={handleChange}
                className="mt-1 p-2 w-full border-2 border-amber-300 rounded-md text-large"
                placeholder="Enter required skills here"
              />
            </div>

            {/* Position */}
            <div className="w-full md:w-1/2 px-4 mb-4">
              <label
                htmlFor="position"
                className="block text-sm font-medium text-black"
              >
                Duration:
              </label>
              <input
                type="text"
                id="position"
                name="position"
                value={formData.position}
                onChange={handleChange}
                className="mt-1 p-2 w-full border-2 border-amber-300 rounded-md text-large"
                placeholder="Enter the duration here"
                required
              />
            </div>

            <div className="w-full md:w-1/2 px-4 mb-4">
              <label
                htmlFor="stipend"
                className="block text-sm font-medium text-black"
              >
                Enter Stipend:
              </label>
              <input
                placeholder="Enter Stipend ex 10,000"
                type="text" // Change type to "text" to allow non-numeric characters
                id="stipend"
                name="stipend"
                value={formData.stipend}
                onChange={handleChange}
                className="mt-1 p-2 w-full border-2 border-amber-300 rounded-md text-large"
                required
              />
            </div>

            {/* Job Description */}
            <div className="w-full px-4 mb-4">
              <label
                htmlFor="job_Description"
                className="block text-sm font-medium text-black"
              >
                Job Description:
              </label>
              <textarea
                id="job_Description"
                name="job_Description"
                value={formData.job_Description}
                onChange={handleChange}
                className="input-field-for-the-post-internship-page-text-area mt-1 p-2 border rounded-md w-full"
                placeholder="Write Internship description"
                rows="4"
                required
              ></textarea>
            </div>

            {/* Submit Button */}
            <div className="post-internship-btn-for-the-post-intership-page w-full px-4 mt-4">
              <button
                type="submit"
                // disabled={posting}
                className="bg-black text-amber-300 py-2 px-4 rounded hover:bg-gray-800 "
              >
                Post Internship
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default PostInternship;
