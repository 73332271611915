import React, { useState, useEffect } from "react";
import {
  FaUser,
  FaEnvelope,
  FaMobile,
  FaLock,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./Registration.css";
import { config } from "../service/service";

const Registration = ({ closePopup, openLogin }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    number: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [showEmailOtpInput, setShowEmailOtpInput] = useState(false);
  const [emailOtp, setEmailOtp] = useState("");
  const [emailOtpTimer, setEmailOtpTimer] = useState(120); // 2 minutes in seconds
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let emailTimer;
    if (showEmailOtpInput && emailOtpTimer > 0) {
      emailTimer = setInterval(() => {
        setEmailOtpTimer((prev) => prev - 1);
      }, 1000);
    } else if (emailOtpTimer === 0) {
      clearInterval(emailTimer);
      setShowEmailOtpInput(false);
    }

    return () => clearInterval(emailTimer);
  }, [showEmailOtpInput, emailOtpTimer]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.fullName) newErrors.fullName = "Full Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email))
      newErrors.email = "Invalid email address";
    if (!formData.number) newErrors.number = "Mobile Number is required";
    else if (!/^[0-9]{10}$/i.test(formData.number))
      newErrors.number = "Invalid mobile number";
    if (!formData.password) newErrors.password = "Password is required";
    if (!formData.confirmPassword)
      newErrors.confirmPassword = "Confirm Password is required";
    else if (formData.password !== formData.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match";

    return newErrors;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isOtpVerified) {
      alert("Please verify your OTP before proceeding.");
      return; // Exit the function if OTP is not verified
    }
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await fetch(
        `${config.backend_url}/api/newstudents/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fullName: formData.fullName,
            email: formData.email,
            password: formData.password,
            number: formData.number,
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log(result);

        let count = {
          verified_apply: 0,
          search: 0,
          opportunities: 0,
        };
        count = JSON.stringify(count);
        console.log(result.userId);
        // Store user data in localStorage
        localStorage.setItem("userId", result.userId);
        localStorage.setItem("token", result.token);
        localStorage.setItem("email", result.email);
        localStorage.setItem("count", count);
        navigate("/student/policiesTerms");
      } else {
        const errorResponse = await response.json();
        if (response.status === 409) {
          setErrors({ email: errorResponse.error });
        } else {
          console.error("Unknown error occurred");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const sendEmailOTP = async () => {
    setShowEmailOtpInput(true);
    setIsLoadingOtp(true);
    try {
      const response = await fetch(
        `${config.backend_url}/api/newstudents/sendotp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: formData.email,
          }),
        }
      );
      if (response.status === 200) {
        alert("Email OTP Sent Successfully!");
      } else if (response.status === 202) {
        setShowEmailOtpInput(false);
        alert("Email Already Exists!");
      }
    } catch (error) {
      alert("Something went wrong");
    } finally {
      setIsLoadingOtp(false);
      setEmailOtpTimer(120); // Reset timer
    }
  };

  const verifyEmailOTP = async () => {
    setIsLoadingOtp(true);
    try {
      const response = await fetch(
        "https://backend.internsbee.org/api/newstudents/verifyotp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            otp: emailOtp,
            email: formData.email,
          }),
        }
      );
      if (response.status === 200) {
        alert("Email OTP Verified Successfully!");
        setIsOtpVerified(true);
        setShowEmailOtpInput(false);
      }
      if (response.status === 202) {
        alert("OTP is Wrong!");
      }
    } catch (error) {
      alert("Something went wrong");
    } finally {
      setIsLoadingOtp(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };
  // Handle name, email, contact, Account No.,ifsc code input validations
  const handleCharInput = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
  };

  const handleContactNumberInput = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    if (value.length > 10) {
      e.target.value = value.slice(0, 10);
    } else {
      e.target.value = value;
    }
  };

  const handleEmailInput = (e) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(e.target.value)) {
      e.target.setCustomValidity("Please enter a valid email address");
    } else {
      e.target.setCustomValidity("");
    }
  };

  return (
    <div>
      <div className="container w-full mx-auto px-4 md:px-8 lg:px-16 xl:px-24 bg-white p-4 border">
        <div className="flex justify-center flex-col lg:flex-row items-center mt-0">
          <div className="w-full">
            <h1 className="text-3xl font-semibold mb-4 text-center">
              Student Registration
            </h1>
            <form className="space-y-6" onSubmit={onSubmit}>
              {/* Full Name Input */}
              <div className="flex flex-col mb-4">
                <div className="flex items-center">
                  <FaUser className="mr-2 inline-block" size={25} />
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    value={formData.fullName}
                    onChange={onChange}
                    onInput={handleCharInput}
                    className="px-2 mt-1 p-2 flex-grow border rounded"
                    placeholder="Enter The Full Name"
                    style={{ width: "100%" }}
                  />
                </div>
                {errors.fullName && (
                  <p className="text-red-500">{errors.fullName}</p>
                )}
              </div>

              {/* Email Input */}
              <div className="flex flex-col mb-4">
                <div className="flex items-center">
                  <FaEnvelope className="mr-2 inline-block" size={25} />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={onChange}
                    onInput={handleEmailInput}
                    className="mt-1 p-1 flex-grow border rounded"
                    placeholder="Enter Email"
                    style={{ width: "100%" }}
                  />
                  <button
                    type="button"
                    onClick={sendEmailOTP}
                    className={`px-2 ml-2 ${
                      isOtpVerified ? "bg-green-500" : "bg-black"
                    } text-white text-sm py-1 rounded hover:bg-black`}
                    disabled={isLoadingOtp}
                  >
                    {isOtpVerified
                      ? "Verified"
                      : isLoadingOtp
                      ? "Sending..."
                      : "Send OTP"}
                  </button>
                </div>
                {errors.email && <p className="text-red-500">{errors.email}</p>}
              </div>

              {/* Email OTP Input */}
              {showEmailOtpInput && !isOtpVerified && (
                <div className="flex flex-col mb-4">
                  <div className="flex items-center">
                    <div className="mr-2">{formatTime(emailOtpTimer)}</div>
                    <input
                      type="number"
                      id="emailOtp"
                      name="emailOtp"
                      value={emailOtp}
                      onChange={(e) => setEmailOtp(e.target.value)}
                      className="mt-1 p-1 flex-grow border rounded"
                      placeholder="Enter Email OTP"
                      style={{ width: "100%" }}
                    />
                    <button
                      type="button"
                      onClick={verifyEmailOTP}
                      className="px-2 ml-2 bg-black text-white text-sm py-1 rounded hover:bg-black"
                      disabled={isLoadingOtp}
                    >
                      {isLoadingOtp ? "Verifying..." : "Verify OTP"}
                    </button>
                  </div>
                </div>
              )}

              {/* Mobile Input */}
              <div className="flex flex-col mb-4">
                <div className="flex items-center">
                  <FaMobile className="mr-2 inline-block" size={25} />
                  <input
                    type="text"
                    id="number"
                    name="number"
                    value={formData.number}
                    onChange={onChange}
                    onInput={handleContactNumberInput}
                    className="mt-1 p-2 flex-grow border rounded"
                    placeholder="Enter Mobile No"
                    style={{ width: "100%" }}
                  />
                </div>
                {errors.number && (
                  <p className="text-red-500">{errors.number}</p>
                )}
              </div>

              {/* Password Input */}
              <div className="flex flex-col mb-4">
                <div className="flex items-center">
                  <FaLock className="mr-2 inline-block" size={25} />
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={onChange}
                    className="px-2 mt-1 p-2 flex-grow border rounded"
                    placeholder="Password"
                    style={{ width: "100%" }}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="px-2 ml-2 bg-black text-white py-2 rounded hover:bg-black"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                {errors.password && (
                  <p className="text-red-500">{errors.password}</p>
                )}
              </div>

              {/* Confirm Password Input */}
              <div className="flex flex-col mb-4">
                <div className="flex items-center">
                  <FaLock className="mr-2 inline-block" size={25} />
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={onChange}
                    className="mt-1 p-2 flex-grow border rounded"
                    placeholder="Confirm Password"
                    style={{ width: "100%" }}
                  />
                  <button
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                    className="px-2 ml-2 bg-black text-white py-2 rounded hover:bg-black"
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                {errors.confirmPassword && (
                  <p className="text-red-500">{errors.confirmPassword}</p>
                )}
              </div>

              <button
                type="submit"
                className="w-full text-black bg-orange-400 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Sign Up
              </button>
              <p className="text-sm font-light text-gray-500 dark:text-black">
                Already have an account?
                <a
                  onClick={() => {
                    openLogin();
                    closePopup();
                  }}
                  className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Log in
                </a>
              </p>
            </form>
            <button
              onClick={closePopup}
              className="w-full text-black bg-white border-1 border-solid border-black hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
