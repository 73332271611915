import React, { useState, useEffect } from 'react';
import '../ResponsiveCss/ResponsiveCss.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Logo from '../../Assets/white_header1.png'
// import Footer from '../Footer/Footer';
import Footer from '../Footer';
import Internal_Navbar from '../UpdatedNav/Internal_Navbar';
import { config } from '../../service/service';
import RefundModal from '../../../Employer/Component/Packages/RefundModal';
import {getTransaction} from '../../../Employer/apis/PaymentAPI';
const MonthlyPackages = () => {
    const [monthlyPackage, setMonthlyPackage] = useState([]);
    const [endDate, setendDate] = useState();
    const [active, setActive] = useState()
    const [isRefundModalOpen, setRefundModalOpen] = useState(false);
    const [token, setToken] = useState(''); // Use state for token
    const [paymentId, setPaymentId] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [refundDate, setRefundDate] = useState('');
    const [refundAmount, setRefundAmount] = useState(0);

    useEffect(() => {
        const savedToken = localStorage.getItem('token');
        setToken(savedToken); // Set the token in state
    }, []);
    const handlePayment = async () => {
        try {
            const orderUrl = '/api/newstudents/payment/order';
            const url = config.backend_url;
    
            // console.log('Creating order at:', url + orderUrl);
    
            // Create order on the server
            const { data } = await axios.post(url + orderUrl, {
                amount: 399, // in rupees
                currency: 'INR',
                receipt: 'receipt#1',
            });
    
            console.log('Order created successfully:', data);
            
            const options = {
                key: 'rzp_test_hmT62XLvUL5N9Q', // Enter the Key ID generated from the Dashboard
                amount: data.amount,
                currency: data.currency,
                name: 'Interns Bee',
                description: 'Test Transaction',
                image: Logo,
                order_id: data.orderId,
                handler: async (response) => {
                    try {
                        console.log('Payment Response:', response);
    
                        const paymentVerificationUrl = '/api/newstudents/payment/verify';
                        console.log('Verifying payment at:', url + paymentVerificationUrl);
    
                        const verifyResponse = await axios.post(url + paymentVerificationUrl, {
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_signature: response.razorpay_signature,
                            amount: data.amount
                        }, {
                            headers: {
                                'Authorization': token
                            }
                        });
    
                        console.log('Payment Verification Response:', verifyResponse);
    
                        if (verifyResponse.data.success) {
                            alert('Payment Successful');
    
                            // Update API
                            const updateUrl = `${config.backend_url}/api/newstudents/premium`;
                            console.log('Updating user data at:', updateUrl);
    
                            const patchResponse = await axios.patch(updateUrl, {}, {
                                headers: {
                                    "Content-Type": "application/json",
                                    "Authorization": localStorage.getItem('token')
                                }
                            });
    
                            console.log('Patch Response:', patchResponse.data);
                            localStorage.setItem('count', JSON.stringify(patchResponse.data.data));
                            window.location.assign('/student/monthlyplan');
                        } else {
                            alert('Payment Verification Failed');
                        }
                    } catch (error) {
                        console.error('Error in payment handler:', error);
                        alert('An error occurred during payment verification.');
                    }
                },
                prefill: {
                    name: "Vedant Rajankar",
                    email: "vedantassignment05@gmail.com",
                    contact: "9999999999",
                },
                notes: {
                    address: "Corporate Office",
                },
                theme: {
                    color: "#3399cc",
                },
            };
    
            const rzp = new window.Razorpay(options);
            rzp.on('payment.failed', (response) => {
                console.error('Payment Failed:', response);
                alert('Payment Failed. Please try again.');
            });
            rzp.open();
        } catch (error) {
            console.error('Error in handlePayment:', error);
            alert('An error occurred while initiating payment.');
        }
    };
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.backend_url}/api/students/students-monthly-package`);
                const data = await response.json();
                console.log(data);
                setMonthlyPackage(data); // Assuming the response is an array with a single object



            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchEndDate = async () => {
            try {
                const res = await axios.get(`${config.backend_url}/api/newstudents/getuser`, {
                    headers: {
                        'Authorization': token
                    }
                })
                const formatDate = (dateString) => {
                    const date = new Date(dateString);
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
                    const year = date.getFullYear();
                    return `${day}/${month}/${year}`;
                };
                const formattedDate = formatDate(res.data.data.premium.endDate)
                setendDate(formattedDate)
                setActive(res.data.data.premium.active)
            } catch (error) {
                console.error('Error fetching End date:', error);

            }
        }
        const fetchLastTransaction = async () => {
            try {
                const res = await getTransaction();
                console.log(res.data);
                setPaymentId(res.data.result.paymentID);
                setRefundDate(res.data.result.createdAt);
                setRefundAmount(res.data.result.totalAmount);
                setTransactionId(res.data.result._id);
            } catch (error) {
                console.log("Error In fetching ", error);
            }
        };
        fetchData();
        fetchEndDate()
        fetchLastTransaction();
    }, []);

    const handleRefund = async () => {
        try {
            const data = {
                amount: refundAmount,
                payment_id: paymentId,
                transaction_id: transactionId
            };
            const res = await axios.post('https://backend.internsbee.org/api/newemployee/refund', data,
                {
                    headers: {
                        'Authorization': token
                    }
                }
            );
            alert('Refund Requested Successfully');
        } catch (error) {
            console.error('Error requesting refund:', error);
        }
    };
    return (
        <>
            <Internal_Navbar />
            <section className="text-gray-600 body-font flex">
                {/* <div><Sidebar /></div> */}
                <div className="container px-5 py-5 mx-auto">
                    <div className="flex flex-col text-center w-full mt-20">
                        <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-black">Monthly Packages</h1>
                        {/* <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-black">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical.</p> */}
                        <div className="flex mx-auto border-2 border-amber-300 rounded overflow-hidden mt-6 ">
                            <Link to={'/student/freeplan'}>
                                <button
                                    className={"py-1 px-4 bg-amber-300 text-black focus:outline-none"}
                                >
                                    Freemium
                                </button>
                            </Link>
                            <Link to={'/student/monthlyplan'}>
                                <button
                                    className={"py-1 px-4 text-black focus:outline-none border-b-2 border-indigo-600"}
                                    title="This functionality is under development"
                                >
                                    Premium
                                </button>
                            </Link>
                        </div>
                    </div>

                    {
                        monthlyPackage && (
                            monthlyPackage.map((data, i) => {
                                return (
                                    <div className=" width-set-for-the-premium-or-freemium-packages w-full md:w-1/2 mx-auto">
                                        <div className="p-4 xl:w-full md:w-1/2 w-full">
                                            <div className="Freecards h-full p-6 rounded-lg flex flex-col relative overflow-hidden">
                                                <div className="Freecard p-4 w-full">
                                                    <div className="Freecard h-full p-6 rounded-lg border-2 border-amber-300 flex flex-col relative overflow-hidden">
                                                        <span className="bg-black text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">POPULAR</span>
                                                        <h2 className="text-sm tracking-widest title-font mb-1 font-medium">PRO</h2>
                                                        <h1 className="text-5xl text-black leading-none flex items-center pb-4 mb-4 border-b border-amber-300">
                                                            <span>{`₹${data.monthlyPackage_Price}`}</span>
                                                            <span className="text-lg ml-1 font-normal text-black">/mo</span>
                                                        </h1>
                                                        <p className="flex items-center text-black mb-2">
                                                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                                                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                                    <path d="M20 6L9 17l-5-5"></path>
                                                                </svg>
                                                            </span>{data.searches} Searches
                                                        </p>

                                                        <p className="flex items-center text-black mb-2">
                                                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                                                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                                    <path d="M20 6L9 17l-5-5"></path>
                                                                </svg>
                                                            </span>{data.opportunities} Opportunities
                                                        </p>

                                                        <p className="flex items-center text-black mb-2">
                                                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                                                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                                    <path d="M20 6L9 17l-5-5"></path>
                                                                </svg>
                                                            </span>{data.verified_application}
                                                        </p>


                                                        <p className="flex items-center text-black mb-6">
                                                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center  text-white rounded-full flex-shrink-0" style={{ backgroundColor: '#FFBD59' }}>                     <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                                <path d="M20 6L9 17l-5-5"></path>
                                                            </svg>
                                                            </span>{data.dedicated_crm}
                                                        </p>



                                                        {/* <Link to="/subscribepackage"> */}

                                                        <button onClick={handlePayment} className="flex items-center mt-auto text-black bg-amber-300 border-0 py-2 px-4 w-full focus:outline-none hover:bg-amber-400 rounded">
                                                            Subscribe
                                                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                            </svg>
                                                        </button>

                                                        {/* </Link> */}

                                                        <div>
                                                            {active ? (
                                                                <p className="text-xs text-black mt-3">
                                                                    Your Subscription will end on <span className='font-bold text-sm'>{endDate}</span>.
                                                                </p>
                                                            ) : (
                                                                <p className="text-xs text-black mt-3">
                                                                    If the payment is done but the Subscription is not active, <button className="underline text-blue-600" onClick={() => setRefundModalOpen(true)}>click here for a refund</button>.
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }))

                    }
                </div>
            </section>
            <RefundModal
                isOpen={isRefundModalOpen}
                onClose={() => setRefundModalOpen(false)}
                onRefund={handleRefund}
                date={refundDate}
                amount={refundAmount}
                payment_id={paymentId}
            />
            <div>
                <Footer />
            </div>

        </>
    );
}

export default MonthlyPackages;