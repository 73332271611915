import React, { useState } from 'react';
import axios from 'axios';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Sidebar from '../Sidebar/Sidebar';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const AccountSettings = () => {
  const emailId = localStorage.getItem('email');
  const userId = localStorage.getItem('userId'); // Now using userId from localStorage

  const [isEditing, setIsEditing] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    email: '',
    currentPassword: '',
    newPassword: '',
  });
  const [tempEmployeeData, setTempEmployeeData] = useState({
    email: '',
    currentPassword: '',
    newPassword: '',
  });

  const [showcurrentPassword, setShowcurrentPassword] = useState(false);
  const [shownewPassword, setShownewPassword] = useState(false);
  const [message, setMessage] = useState('');

  const handleInputChange = (field, value) => {
    setTempEmployeeData(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const togglecurrentPasswordVisibility = () => {
    setShowcurrentPassword(!showcurrentPassword);
  };

  const togglenewPasswordVisibility = () => {
    setShownewPassword(!shownewPassword);
  };

  const handleChangePassword = async () => {
    if (!tempEmployeeData.currentPassword || !tempEmployeeData.newPassword) {
      alert('Please enter both current and new passwords.');
      return;
    }

    try {
      const response = await axios.patch(
        `https://backend.internsbee.org/api/newemployee/updateemployer/password/${userId}`,
        {
          currentPassword: tempEmployeeData.currentPassword,
          newPassword: tempEmployeeData.newPassword,
        }
      );

      if (response.status === 200) {
        alert('Password changed successfully.');
        // Optionally, you can clear the input fields after successful password change
        setTempEmployeeData({
          currentPassword: '',
          newPassword: '',
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        alert(error.response.data.message || 'Failed to change password.');
      } else {
        alert('An error occurred while changing the password.');
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div className='flex flex-col md:flex-row'>
        <Sidebar />
        <div className="accsec max-w-md mx-auto mt-8 p-6 rounded-md shadow-md mb-3 md:mb-0 md:mt-28 md:w-2/3 lg:w-1/3" style={{ backgroundColor: '#FFBD59', width: "100%", height: "100%" }}>
          <h2 className="text-2xl font-semibold mb-4">Account Settings</h2>

          <div className="mb-4">
            <label htmlFor="email" className="text-sm font-medium text-black">
              Email
            </label>
            <div className="flex items-center">
              <input
                type="text"
                id="email"
                className="border p-2 w-full"
                readOnly
                value={emailId}
              />
            </div>
          </div>

          <div className="mb-4">
            <label htmlFor="currentPassword" className="text-sm font-medium text-black">
              Current Password
            </label>
            <div className="flex items-center relative">
              <input
                type={showcurrentPassword ? 'text' : 'password'}
                id="currentPassword"
                className="border p-2 w-full"
                value={tempEmployeeData.currentPassword}
                onChange={(e) => handleInputChange('currentPassword', e.target.value)}
              />
              <button
                type="button"
                className="absolute right-2"
                onClick={togglecurrentPasswordVisibility}
              >
                {showcurrentPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>

          <div className="mb-4">
            <label htmlFor="newPassword" className="text-sm font-medium text-black">
              New Password
            </label>
            <div className="flex items-center relative">
              <input
                type={shownewPassword ? 'text' : 'password'}
                id="newPassword"
                className="border p-2 w-full"
                value={tempEmployeeData.newPassword}
                onChange={(e) => handleInputChange('newPassword', e.target.value)}
              />
              <button
                type="button"
                className="absolute right-2"
                onClick={togglenewPasswordVisibility}
              >
                {shownewPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>

          {message && <div className="text-red-500 mb-4">{message}</div>}

          <div className='flex justify-center'>
            <button
              className="ml-2 px-4 py-2 bg-black text-white rounded hover:bg-black"
              onClick={handleChangePassword}
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AccountSettings;
