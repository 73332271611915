import axios from "axios";

const getHeader = () => {
    const token = localStorage.getItem("token");
    return {
        Authorization: token,
    };
};

// Create an Axios instance with default settings
const apiClient = axios.create({
    baseURL: "https://backend.internsbee.org",
    // timeout: 5000,
    headers: getHeader()
});
// Use an interceptor to dynamically set the headers before each request
apiClient.interceptors.request.use((config) => {
    config.headers = {
      ...config.headers,
      ...getHeader(),
    };
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
// Custom error handler
const handleError = (error) => {
    if (error.response) {
        console.error("Server responded with an error:", error.response.data);
    } else if (error.request) {
        console.error("No response received:", error.request);
    } else {
        console.error("Error setting up request:", error.message);
    }
    throw error;
};


const orderAPI = async (data) => {
    try {
        const endpoint = "/api/newemployee/payment/order";
        const response = await apiClient.post(endpoint, data);
        return response;
    } catch (error) {
        handleError(error);
    }
};
const paymentVerifyAPI = async (data) => {
    try {
        const endpoint = "/api/newemployee/payment/verify";
      
            const response = await apiClient.post(endpoint, data);
        
        return response;
    } catch (error) {
        handleError(error);
    }
};
const montlyPackageAPI = async (data) => {
    try {
        const endpoint = "/api/newemployee/montlypackage";
        const response = await apiClient.patch(endpoint, data);
        return response;
    } catch (error) {
        handleError(error);
    }
};
const annuallyPackageAPI = async (data, headers = {}) => {
    try {
        const endpoint = "/api/newemployee/annuallypackage";
        const response = await apiClient.patch(endpoint, data, { headers });
        return response;
    } catch (error) {
        handleError(error);
    }
};
const getUserAPI = async (data, headers = {}) => {
    try {
        const endpoint = "/api/newemployee/getuser";
        const response = await apiClient.get(endpoint, { headers });
        return response;
    } catch (error) {
        handleError(error);
    }
};
const getTransaction = async() =>{
    try {
        const endpoint = "/api/newemployee/transaction";
        const response = await apiClient.get(endpoint);
        return response;
    } catch (error) {
        handleError(error);
    }
}
const postRefund = async(data) =>{
    try {
        const endpoint = "/api/newemployee/refund";
        const response = await apiClient.post(endpoint,data);
        return response;
    } catch (error) {
        handleError(error);
    }
}
export {

    getHeader,
    orderAPI,
    paymentVerifyAPI,
    montlyPackageAPI,
    annuallyPackageAPI,
    getUserAPI,
    getTransaction,
    postRefund
}