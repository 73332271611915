import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { Link } from "react-router-dom";
import "./PackagesPage.css";
import Footer from "../Footer/Footer";
import Logo from '../../Assets/Internsb.png';
import RefundModal from './RefundModal';
import { getHeader, getTransaction, getUserAPI, montlyPackageAPI, orderAPI, paymentVerifyAPI, postRefund } from '../../apis/PaymentAPI';
import axios from "axios";
import { config } from "../../../Student/service/service";



const MonthlyPackages = () => {
  
  const [monthlyPackage, setMonthlyPackage] = useState([]);
  const [endDate, setendDate] = useState();
  const [active, setActive] = useState();
  const [isRefundModalOpen, setRefundModalOpen] = useState(false);
  const [userDetails, setuserDetails] = useState({ email: '', name: '', number: '' });
  const [token, setToken] = useState(''); // Use state for token
  const [paymentId, setPaymentId] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [refundDate, setRefundDate] = useState('');
  const [refundAmount, setRefundAmount] = useState(0);
  const [activeBtn, setActiveBtn] = useState("Monthly");

  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    setToken(savedToken); // Set the token in state
  }, []);

  const handleSubscribe = async (amount) => {
    // Ensure token is available before making API call
    if (!token) {
      console.error('Token is undefined');
      return;
    }

    const orderUrl = '/api/newstudents/payment/order';
    const reqdata = {
      amount: amount,
      currency: 'INR',
      receipt: 'receipt#1',
    };
    const { data } = await orderAPI(reqdata);

    const options = {
      key: 'rzp_test_hmT62XLvUL5N9Q',
      amount: data.amount,
      currency: data.currency,
      name: 'Interns Bee',
      description: 'Test Transaction',
      image: Logo,
      order_id: data.orderId,
      handler: async (response) => {
        console.log(token);
        const verifyResponse = await axios.post(
          'https://backend.internsbee.org/api/newstudents/payment/verify',
          {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            amount: reqdata.amount
          },
          {
            headers: {
              'Authorization': token // Use the token from state
            }
          }
        );

        if (verifyResponse.data.success) {
          alert('Payment Successful');
          try {
            const data = {};
            const response = await axios.patch(
              'https://backend.internsbee.org/api/newemployee/montlypackage',
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": token // Use the token from state
                },
              }
            );
            const responseData = response.data.data
            let count = {
              searches: responseData.searches,
              internshipEnquiry: responseData.internshipEnquiry,
              verifiedApplication: responseData.verifiedApplication,
              ResumeView:responseData.ResumeView
            };
            count = JSON.stringify(count);
            localStorage.setItem('count', count)
            
            window.location.assign('/employer/home');
          } catch (error) {
            console.error('Error updating package:', error);
          }
        } else {
          alert('Payment Verification Failed');
        }
      },
      prefill: {
        name: userDetails.name,
        email: userDetails.email,
        contact: userDetails.number,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#3399cc',
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://backend.internsbee.org/api/adminmonthlypackage");
        const data = await response.json();
        setMonthlyPackage(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchEndDate = async () => {
      try {
        const headers = {
          headers: {
            'Authorization': token // Use the token from state
          }
        };
        const res = await getUserAPI(headers);
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        };
        const formattedDate = formatDate(res.data.data.package.end_date);
        setendDate(formattedDate);
        setuserDetails({
          number: res.data.data.number,
          email: res.data.data.email,
          name: res.data.data.name,
        });
        setActive(res.data.data.package.active);
      } catch (error) {
        console.error('Error fetching End date:', error);
      }
    };

    const fetchLastTransaction = async () => {
      try {
        const res = await axios.get(`${config.backend_url}/api/newstudent/transaction`,{
          headers:{
            'Authorization':token
          }
        });
        console.log(res.data);
        setPaymentId(res.data.result.paymentID);
        setRefundDate(res.data.result.createdAt);
        setRefundAmount(res.data.result.totalAmount);
        setTransactionId(res.data.result._id);
      } catch (error) {
        console.log("Error In fetching ", error);
      }
    };

    if (token) {
      fetchEndDate();
      fetchData();
      fetchLastTransaction();
    }
  }, [token]);


  const handleRefund = async () => {
    try {
      const data = {
        amount: refundAmount,
        payment_id: paymentId,
        transaction_id: transactionId
      };
      const res = await postRefund(data);
      alert('Refund Requested Successfully');
    } catch (error) {
      console.error('Error requesting refund:', error);
    }
  };

  
  return (
    <>
      <div>
        <Navbar />
      </div>
  
      <section className="displayBlock text-gray-600 body-font flex flex-wrap md:flex-nowrap">
        <div className="w-full md:w-1/4">
          <Sidebar />
        </div>
        <div className="w-full md:w-3/4 mx-auto">
          <div className="flex flex-col text-center w-full mt-7">
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-black">
              Monthly Packages
            </h1>
            <div className="flex mx-auto border-2 border-amber-300 rounded overflow-hidden mt-6">
              <Link to={"/employer/packages"}>
                <button
                  className="py-1 px-4 text-black focus:outline-none "
                  onClick={() => setActiveBtn('Monthly')}
                  style = {{backgroundColor:activeBtn === 'Monthly' ? "#FFC107" : 'white'}}
                >
                  Monthly/Pro
                </button>
              </Link>
              <Link to={"/employer/anuallypackage"}>
                <button
                  className="py-1 px-4 text-black focus:outline-none  border-indigo-600"
                  onClick={() => setActiveBtn('Anually')}
                  style = {{backgroundColor:activeBtn ==='Anually' ? "#FFC107" : 'white'}}
                  title="This functionality is under development"
                >
                  Anually/Business
                </button>
              </Link>
            </div>
          </div>
  
          {monthlyPackage && monthlyPackage.map((data, i) => (
            <div key={i} className="w-full md:w-1/2 mx-auto">
              <div className="p-2 xl:w-full md:w-1/2 w-full">
                <div className="p-6 rounded-lg border-2 border-amber-300 flex flex-col relative overflow-hidden">
                  <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                    BUSINESS
                  </h2>
                  <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-amber-300">
                    <span>₹{data.monthlyPackage_Price}</span>
                    <span className="text-lg ml-1 font-normal text-black">
                      /monthly
                    </span>
                  </h1>
                  <p className="flex items-center text-gray-600 mb-2">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    {data.searches} searches
                  </p>
                  <p className="flex items-center text-gray-600 mb-2">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    {data.internship_enquiry} Internship Enquiry
                  </p>
                  <p className="flex items-center text-gray-600 mb-2">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    {data.verified_appication} Verified Application
                  </p>
                  <p className="flex items-center text-gray-600 mb-2">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    {data.resume_view} Resume View
                  </p>
                  <p className="flex items-center text-gray-600 mb-6">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-white rounded-full flex-shrink-0">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 6L9 17l-5-5"></path>
                      </svg>
                    </span>
                    Dedicated CRM
                  </p>
  
                  <button
                    onClick={() => handleSubscribe(data.monthlyPackage_Price)}
                    className="flex items-center mt-auto text-black bg-amber-300 border-0 py-2 px-4 w-full focus:outline-none hover:bg-amber-500 rounded"
                  >
                    Subscribe
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4 ml-auto"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                  {active ? (
                    <p className="text-xs text-black mt-3">
                      Your Subscription will end on <span className='font-bold text-sm'>{endDate}</span>.
                    </p>
                  ) : (
                    <p className="text-xs text-black mt-3">
                      If the payment is done but the Subscription is not active, <button className="underline text-blue-600" onClick={() => setRefundModalOpen(true)}>click here for a refund</button>.
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
  
      <RefundModal
        isOpen={isRefundModalOpen}
        onClose={() => setRefundModalOpen(false)}
        onRefund={handleRefund}
        date={refundDate}
        amount={refundAmount}
        payment_id={paymentId}
      />
  
      <div>
        <Footer />
      </div>
    </>
  );
};

export default MonthlyPackages;

